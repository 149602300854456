<template>
  <div class="footer-question" :style="{ 'background-color': survey.colors.footerBg }">
    <div class="footer-logo" v-if="survey.images[1] && survey.images[1].value">
      <img :src="survey.images[1].value" class="small-logo-footer">
    </div>
    <button class="next-question" v-if="footerAction || active" @click="submit('')">{{ question.nextLabel }}</button>
  </div>
</template>

<script>
export default {
  name: 'content-footer',
  props: ['question', 'survey'],
  data () {
    return {
      active: false,
      placeholder: ''
    }
  },
  mounted () {
    this.$root.$on('show-btn', payload => {
      this.active = payload
    })

    this.$root.$on('hidden-logo-footer', payload => {
      this.showLogoBrand = payload
      const logo = document.querySelector('.small-logo-footer')
      if (!payload) {
        logo.style.opacity = '0'
      } else {
        logo.style.opacity = '1'
      }
    })
  },
  computed: {
    questionsList () {
      const qs = this.$store.getters['question/getQuestion']
      return qs
    },
    footerAction () {
      let showBtn = true
      if (this.question.required) {
        showBtn = false
      }

      if (this.question.type === 'referral') {
        showBtn = false
      }

      if (this.question.type === 'matrix' && this.question.required) {
        showBtn = false
      }

      if (this.question.type === 'text' && this.question.required) {
        showBtn = false
      }

      if ((this.question.ui === 'singleOther' || this.question.ui === 'single' || this.question.ui === 'multiple' || this.question.ui === 'multipleOther') && this.question.required) {
        showBtn = false
      }

      if (this.question.type === 'enum' && this.question.required) {
        showBtn = false
      }

      return showBtn
    }
  },
  methods: {
    submit () {
      this.$root.$emit('footer-submit', true)
    }
  }
}
</script>
<style lang="scss">
.footer-question {
  position: relative;
  width: 100%;
  height: 140px;
  background: var(--footerBg);
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-logo {
    min-width: 90px;
    max-width: 260px;
    height: 75px;
    .small-logo-footer {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      transition: opacity 0.5s ease-in-out;
    }
  }
  .next-question {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 8px 10px 0 rgba(255, 255, 255, 0.030);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--buttonBg);
    color: var(--buttonColor);
    background-image: linear-gradient(
      -60deg,
      transparent, transparent 40%,
      #ffffff44 40%, #ffffff44 60%,
      transparent 60%, transparent 100%
    );
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position-x: 150%;
  }
  .next-question:hover {
    background-position-x: -150%;
    transition: background-position-x 1s;
    cursor: pointer;
  }
}

// Responsivity to mobile
@media only screen and (max-width: 850px) {
  .footer-question {
    .footer-logo {
      height: 100%;
      max-width: 47%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .small-logo-footer {
        max-height: 60%;
        margin-bottom: 10px;
        transition: opacity 0.5s ease-in-out;
      }
    }
    .next-question {
      top: 30px;
      min-width: 90px;
      min-height: 35px;
      font-size: 15px;
      padding: 8px 10px;
    }
  }
}
</style>
